import React, { Component, Fragment } from "react";
import { NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { FeatureActionType } from "../helpers/fixcodes.js";
import { getRequestParams } from "../../src/helpers/globalfunctions.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TopBarComponent } from "./TopBarComponent.jsx";

var nav = document.getElementById("nav-item dropdown");

export class SideBarComponent extends Component {
  _isMounted = false;
  static displayName = SideBarComponent.name;
  nav = document.getElementById("access_nav");
  constructor(props) {
    super(props);
    this.state = {
      pageName: "Dashboard",
      menuHtml: null,
      userMenu: [],
      accountProfileImage: "../assets/img/defaultlogo.png",
      fileGuid: "00000000-0000-0000-0000-000000000000",
      fileUrl: "../assets/img/defaultlogo.png",
      userTypeId: Number(sessionStorage.getItem("session_userTypeId")),
    };
  }
  componentDidMount() {
    document.querySelector("#lblPageTitle").innerHTML =
      sessionStorage.getItem("session_PageName") === null
        ? "Dashboard"
        : sessionStorage.getItem("session_PageName");
    this._isMounted = true;
    //this.getUserRoleFeatureList();
    //this.getAccountLogo();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleLogout = async (e) => {
    e.preventDefault();
    //const requestParams = getRequestParams('GET');
    //let userId = parseInt(sessionStorage.getItem("session_userId"));
    //let loginHistoryId = parseInt(sessionStorage.getItem("session_LoginHistoryId"));
    //const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/logout/' + userId + '/' + loginHistoryId, requestParams);
    sessionStorage.clear();
    window.location.href = "/";
  };
  getAccountLogo = async () => {
    try {
      // var fileGuid = sessionStorage.getItem("session_fileGuid");
      // var accountLogo = sessionStorage.getItem("session_accountLogoName");
      // var logoExtenstion = sessionStorage.getItem("session_accountLogoExt");
      // if (fileGuid !== "00000000-0000-0000-0000-000000000000") {
      //     const requestParams = getRequestParams('GET');
      //     const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'File/download/' + fileGuid, requestParams);
      //     const data = await response.json();
      //     this.setState({
      //         accountProfileImage: 'data:' + data.payload.mimeType + ';base64,' + data.payload.fileContent,
      //         fileUrl: data.payload.fileUrl });
      // }
      // if (accountLogo != "") {
      //     this.setState({
      //         fileUrl: "../assets/img/accountLogo/" + accountLogo + logoExtenstion
      //     });
      // }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getAccountLogo function",
        err
      );
      this.setState({ loadPanelVisible: false });
    }
  };
  getUserRoleFeatureList = async () => {
    try {
      let userId = sessionStorage.getItem("session_userId");
      let planId = sessionStorage.getItem("session_planId");
      const requestParams = getRequestParams("GET");
      this.setState({
        loadPanelVisible: true,
      });

      var sessionmenu = JSON.parse(sessionStorage.getItem("session_menu"));
      if (sessionmenu === null) {
        const response = await fetch(
          process.env.REACT_APP_API_BASE_URL +
          "UserPermission/" +
          userId +
          "/role-feature/" +
          parseInt(planId) +
          "/" +
          Number(FeatureActionType.UTWiz),
          requestParams
        );
        if (response.ok) {
          let data = await response.json();
          sessionStorage.setItem("session_menu", JSON.stringify(data));
          data = JSON.parse(JSON.stringify(data.userRoleFeature));
          data = data.filter((el) => el.isActive === true);
          let menuHtmlItem = this.getMenuHtml(data);
          this.setState({
            menuHtml: menuHtmlItem,
            userMenu: data,
          });
        }
      } else {
        let data = sessionmenu.userRoleFeature;
        data = data.filter((el) => el.isActive === true);
        let menuHtmlItem = this.getMenuHtml(data);
        this.setState({
          menuHtml: menuHtmlItem,
          userMenu: data,
        });
      }
    } catch (err) {
      console.log(
        "Error in " + this.displayName + ".getUserRoleFeatureList function",
        err
      );
      this.setState({ loadPanelVisible: false });
    }
  };
  getMenuHtml(menuFeature) {
    let menuHtmlItem = [];
    menuFeature = JSON.parse(JSON.stringify(menuFeature));
    menuFeature.forEach((menu) => {
      if (menu.parentMenuId === 0) {
        let childMenu = menuFeature.filter(
          (el) => el.parentMenuId === menu.childMenuId
        );
        if (childMenu.length > 0) {
          menuHtmlItem.push(
            <li key={menu.childMenuId} className="dropdown">
              <a
                href={menu.componentPath === "" ? "/" : menu.componentPath}
                className="nav-link has-dropdown"
                title={menu.menuName}
                data-toggle="dropdown"
              >
                <i className={menu.className}></i>
                <span>{menu.menuName}</span>
              </a>
              <ul key={menu.childMenuId} className="dropdown-menu">
                {this.subMenuHtml(menuFeature, menu)}
              </ul>
            </li>
          );
        } else {
          menuHtmlItem.push(
            <NavItem key={menu.childMenuId}>
              <NavLink
                tag={Link}
                to={menu.componentPath}
                title={menu.menuName}
                onClick={(e) => {
                  this.menuClick(e, menu.menuName);
                }}
              >
                <i className={menu.className}></i>
                <span>{menu.menuName}</span>
              </NavLink>
            </NavItem>
          );
        }
      }
    });
    return menuHtmlItem;
  }

  subMenuHtml = (menuFeature, menuList) =>
    menuFeature
      .filter((el) => el.parentMenuId === menuList.childMenuId)
      .map((subchild) =>
        menuFeature.filter((el) => el.parentMenuId === subchild.childMenuId)
          .length === 0 ? (
          <NavItem key={subchild.childMenuId}>
            <NavLink
              tag={Link}
              to={subchild.componentPath}
              title={subchild.menuName}
              onClick={(e) => {
                this.menuClick(e, subchild.menuName);
              }}
            >
              <i className={subchild.className}></i>
              <span>{subchild.menuName}</span>
            </NavLink>
          </NavItem>
        ) : null
      );

  menuClick = async (e, currentPageName) => {
    document.querySelector("#lblPageTitle").innerHTML = currentPageName;
    if (currentPageName === "Fuel (Legacy Site)") {
      let accountId = parseInt(sessionStorage.getItem("session_accountId"));
      let locationId = parseInt(sessionStorage.getItem("session_locationId"));
      let userId = parseInt(sessionStorage.getItem("session_userId"));

      const requestParams = getRequestParams("GET");
      console.log(process.env.REACT_APP_API_BASE_URL);
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL +
        "authentication/wialon-authentication/" +
        accountId +
        "/" +
        locationId +
        "/" +
        userId,
        requestParams
      );
      const data = await response.json();

      if (data.payload != null && data.payload != undefined) {
        window.open(data.payload, "_blank");
      } else {
        toast.success("Invalid access", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
          style: { width: "310px" },
          hideProgressBar: false,
          newestOnTop: false,
        });
      }
    } else {
      this.setState({
        menuName: e.currentTarget.innerText,
        pageName: currentPageName,
      });
      sessionStorage.setItem("session_PageName", currentPageName);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="main-sidebar">
          <aside id="sidebar-wrapper">
            {/* <div className="sidebar-brand">
                            <a href="/">{sessionStorage.getItem("session_accountname")}</a>
                        </div> */}
            <div className="sidebar-brand">
              <a href="/">
                <img
                  src="../assets/img/logo_akasa_air_inside.png"
                  className="Akasa Air"
                  width="180px"
                />
              </a>
              {/* <div className="utwizLogo">
                                a product of
                                <img src="https://www.utwiz.com/wp-content/uploads/2019/05/UTWiz-Logo-small.png" />
                            </div> */}
            </div>
            <div className="sidebar-brand-silverstar-sm">
              <a href="/">A</a>
            </div>
            <div className="companyLogoBox sidebar-brand-sm">
              {/* <img src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Amul_dairy_logo.png" /> */}
              <img src="../assets/img/defaultlogo.png" />
              {/* <img src={this.state.fileUrl} alt="Account Logo" /> */}
            </div>
            <div className="sidebar-brand sidebar-brand-sm">
              {/* <a href="/">{sessionStorage.getItem("session_accountname").charAt(0).toLowerCase().trim()}</a> */}
            </div>
            {/* <ul className='sidebar-menu'>
                            {this.state.menuHtml}                                
                        </ul> */}
            {
              this.state.userTypeId !== 10 ?
                <ul className="sidebar-menu">
                  <li className="nav-item">
                    <a
                      title="Dashboard"
                      className="nav-link"
                      href="/dashboard"
                      onClick={(e) => {
                        this.menuClick(e, "Dashboard");
                      }}
                    >
                      <i className="fas fa-chalkboard"></i>
                      <span>Dashboard</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Schedule Dashboard"
                      className="nav-link"
                      href="/scheduleDashboard"
                      onClick={(e) => {
                        this.menuClick(e, "Schedule Dashboard");
                      }}
                    >
                      <i className="fas fa-calendar-alt"></i>
                      <span>Schedule Dashboard</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Crew Transfers"
                      className="nav-link"
                      href="/dispatch"
                      onClick={(e) => {
                        this.menuClick(e, "Crew Transfers");
                      }}
                    >
                      <i className="fas fa-car"></i>
                      <span>Crew Transfers</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Tracking"
                      className="nav-link"
                      href="/tracking"
                      onClick={(e) => {
                        this.menuClick(e, "Tracking");
                      }}
                    >
                      <i className="fas fa-globe-asia"></i>
                      <span>Tracking</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/"
                      className="nav-link has-dropdown"
                      title="Approvals"
                      data-toggle="dropdown"
                    >
                      <i className="fas fa-thumbs-up"></i>
                      <span>Approvals</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li
                        className="nav-item"
                        style={{
                          display: this.state.userTypeId != 9 ? "block" : "none",
                        }}
                      >
                        <a
                          title="Scheduling Department"
                          className="nav-link"
                          href="/billing"
                          onClick={(e) => {
                            this.menuClick(e, "Scheduling Department");
                          }}
                        >
                          <i className="fas fa-thumbs-up"></i>
                          <span>Scheduling Department</span>
                        </a>
                      </li>
                      {/* <li className="nav-item" style={{ display: this.state.userTypeId != 8 ? 'block' : 'none' }}>
                                        <a title="Transport Department" className="nav-link" href="/transportDepartment" onClick={(e) => { this.menuClick(e, "Transport Department") }}><i className="fas fa-thumbs-up"></i><span>Transport Department</span></a>
                                    </li> */}
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                                <a href="/" className="nav-link has-dropdown" title="Crew Hotel" data-toggle="dropdown">
                                    <i className='fas fa-hotel'></i>
                                    <span>Crew Hotel</span>
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <a title="Crew Hotel" className="nav-link" href="/hotels" onClick={(e) => { this.menuClick(e, "Crew Hotel") }}>
                                            <i className="fas fa-hotel"></i><span>Crew Hotel</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a title="Schedule Approval" className="nav-link" href="/scheduleDashboard" onClick={(e) => { this.menuClick(e, "Schedule Approval") }}>
                                            <i className="fas fa-clock"></i><span>Schedule Approval</span>
                                        </a>
                                    </li>
                                </ul>
                            </li> */}
                  <li className="nav-item">
                    <a
                      href="/"
                      className="nav-link has-dropdown"
                      title="Masters"
                      data-toggle="dropdown"
                    >
                      <i className="fas fa-times"></i>
                      <span>No Show</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a
                          title="Users"
                          className="nav-link"
                          href="/reimburseTransport"
                          onClick={(e) => {
                            this.menuClick(e, "Transportation Reimbursement");
                          }}
                        >
                          <i className="fas fa-rupee-sign"></i>
                          <span>Reimbursement</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/"
                      className="nav-link has-dropdown"
                      title="Masters"
                      data-toggle="dropdown"
                    >
                      <i className="fas fa-server"></i>
                      <span>Masters</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a
                          title="Service Providers"
                          className="nav-link"
                          href="/serviceProviders"
                          onClick={(e) => {
                            this.menuClick(e, "Service Providers");
                          }}
                        >
                          <i className="fas fa-building"></i>
                          <span>Service Providers</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Crew Members"
                          className="nav-link"
                          href="/crewmember"
                          onClick={(e) => {
                            this.menuClick(e, "Crew Members");
                          }}
                        >
                          <i className="fas fa-user-nurse"></i>
                          <span>Crew Members</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Airport"
                          className="nav-link"
                          href="/airport"
                          onClick={(e) => {
                            this.menuClick(e, "Airport");
                          }}
                        >
                          <i className="fas fa-plane"></i>
                          <span>Airport</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Crew Hotel"
                          className="nav-link"
                          href="/hotels"
                          onClick={(e) => {
                            this.menuClick(e, "Crew Hotel");
                          }}
                        >
                          <i className="fas fa-hotel"></i>
                          <span>Crew Hotel</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Users"
                          className="nav-link"
                          href="/user"
                          onClick={(e) => {
                            this.menuClick(e, "Users");
                          }}
                        >
                          <i className="fas fa-user-circle"></i>
                          <span>Users</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/"
                      className="nav-link has-dropdown"
                      title="Configuration"
                      data-toggle="dropdown"
                    >
                      <i className="fas fa-server"></i>
                      <span>Configuration</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a
                          title="Hotel Transport Configuration"
                          className="nav-link"
                          href="/config/transporthotel"
                          onClick={(e) => {
                            this.menuClick(e, "Hotel Transport Configuration");
                          }}
                        >
                          <i className="fas fa-hotel"></i>
                          <span>Hotel Transport Configuration</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Transport Crew Type Configuration"
                          className="nav-link"
                          href="/config/transportcrewtype"
                          onClick={(e) => {
                            this.menuClick(e, "Transport Crew Type Configuration");
                          }}
                        >
                          <i className="fas fa-users"></i>
                          <span>Transport Crew Type Configuration</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Booking Hotel Priority"
                          className="nav-link"
                          href="/config/booking/hotelpriority"
                          onClick={(e) => {
                            this.menuClick(e, "Booking Hotel Priority");
                          }}
                        >
                          <i className="fas fa-users"></i>
                          <span>Booking Hotel Priority</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Email Configuration"
                          className="nav-link"
                          href="/config/email"
                          onClick={(e) => {
                            this.menuClick(e, "Email Configuration");
                          }}
                        >
                          <i className="fas fa-envelope"></i>
                          <span>Email Configuration</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Hotel Booking"
                      className="nav-link"
                      href="/hotelbooking"
                      onClick={(e) => {
                        this.menuClick(e, "Hotel Booking");
                      }}
                    >
                      <i className="fas fa-hotel"></i>
                      <span>Hotel Booking</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Invoice"
                      className="nav-link"
                      href="/invoice"
                      onClick={(e) => {
                        this.menuClick(e, "Invoice");
                      }}
                    >
                      <i className="fas fa-file-invoice"></i>
                      <span>Invoice</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Message"
                      className="nav-link"
                      href="/notification"
                      onClick={(e) => {
                        this.menuClick(e, "Alerts & Notifications");
                      }}
                    >
                      <i className="fas fa-envelope-open-text"></i>
                      <span>Alerts & Notifications</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Rating"
                      className="nav-link"
                      href="/rating"
                      onClick={(e) => {
                        this.menuClick(e, "Rating");
                      }}
                    >
                      <i className="fas fa-star"></i>
                      <span>Rating</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Feedback"
                      className="nav-link"
                      href="/feedback"
                      onClick={(e) => {
                        this.menuClick(e, "Feedback");
                      }}
                    >
                      <i className="fas fa-comment-dots"></i>
                      <span>Feedback</span>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      href="/"
                      className="nav-link has-dropdown"
                      title="Report"
                      data-toggle="dropdown"
                    >
                      <i className="fas fa-server"></i>
                      <span>Report</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <a
                          title="Cancel Hotel"
                          className="nav-link"
                          href="/report/cancel"
                          onClick={(e) => {
                            this.menuClick(e, "Cancel Hotel");
                          }}
                        >
                          <i className="fas fa-hotel"></i>
                          <span>Cancel Hotel</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Stay Hotel"
                          className="nav-link"
                          href="/report/stay"
                          onClick={(e) => {
                            this.menuClick(e, "Stay Hotel");
                          }}
                        >
                          <i className="fas fa-hotel"></i>
                          <span>Stay Hotel</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          title="Airport Geofence Report"
                          className="nav-link"
                          href="/report/geofence"
                          onClick={(e) => {
                            this.menuClick(e, "Airport Geofence Report");
                          }}
                        >
                          <i className="fas fa-file-excel"></i>
                          <span>Airport Geofence Report</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
                :
                <ul className="sidebar-menu">
                  <li className="nav-item">
                    <a
                      title="Dashboard"
                      className="nav-link"
                      href="/dashboard"
                      onClick={(e) => {
                        this.menuClick(e, "Dashboard");
                      }}
                    >
                      <i className="fas fa-chalkboard"></i>
                      <span>Dashboard</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Schedule Dashboard"
                      className="nav-link"
                      href="/scheduleDashboard"
                      onClick={(e) => {
                        this.menuClick(e, "Schedule Dashboard");
                      }}
                    >
                      <i className="fas fa-calendar-alt"></i>
                      <span>Schedule Dashboard</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Crew Transfers"
                      className="nav-link"
                      href="/dispatch"
                      onClick={(e) => {
                        this.menuClick(e, "Crew Transfers");
                      }}
                    >
                      <i className="fas fa-car"></i>
                      <span>Crew Transfers</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Tracking"
                      className="nav-link"
                      href="/tracking"
                      onClick={(e) => {
                        this.menuClick(e, "Tracking");
                      }}
                    >
                      <i className="fas fa-globe-asia"></i>
                      <span>Tracking</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Rating"
                      className="nav-link"
                      href="/rating"
                      onClick={(e) => {
                        this.menuClick(e, "Rating");
                      }}
                    >
                      <i className="fas fa-star"></i>
                      <span>Rating</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      title="Feedback"
                      className="nav-link"
                      href="/feedback"
                      onClick={(e) => {
                        this.menuClick(e, "Feedback");
                      }}
                    >
                      <i className="fas fa-comment-dots"></i>
                      <span>Feedback</span>
                    </a>
                  </li>
                </ul>
            }
          </aside>
        </div>
        <div className="plexitechPoweredByLogo">
          powered by
          <img src="../assets/img/logo-plexitech2.png" />
        </div>
        {/* <TopBarComponent pageName={sessionStorage.getItem('session_PageName')} /> */}
        {/* <TopBarComponent pageName={this.pageName} /> */}
      </Fragment>
    );
  }
}
