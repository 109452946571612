import jsSHA from "jssha"; // npm install jssha
import React, { Component } from 'react';
import { getRequestParams } from '../../src/helpers/globalfunctions.js';

export class LoginComponent extends Component {
    static displayName = LoginComponent.name;

    constructor(props) {
        super(props);
        const queryString = require('query-string');
        var parsed = queryString.parse(this.props.location.search);

        //console.log(parsed.userToken);        
        this.state = {
            userName: '', password: '', isSubmited: false, message: '',
            ipAddress: '127.0.0.1', latitude: 0, longitude: 0,
            errors: {
                userName: '',
                password: ''
            },
            userToken: this.props.location.search !== "" ? parsed.userguid : "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clearControl = this.clearControl.bind(this);
        this.calcHash = this.calcHash.bind(this);
        this.uuidv4 = this.uuidv4.bind(this);
        //console.log(process.env.REACT_APP_API_BASE_URL);
    }

    clearControl() {
        this.setState({
            userName: '', password: '', isSubmited: false, message: '',
            errors: {
                userName: '',
                password: ''
            }
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleValidation() {
        let formIsValid = true;
        try {
            let errors = this.state.errors;
            errors.userName = "";
            errors.password = "";
            this.setState({ message: '' });

            if (this.state.userName === '') {
                formIsValid = false;
                errors.userName = "Please enter username.";
            }

            if (this.state.password === '') {
                formIsValid = false;
                errors.password = "Please enter password.";
            }
            this.setState({ errors: errors });
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleValidation function", err);
            formIsValid = false;
        }
        return formIsValid;
    }

    calcHash(value, securityKey) {
        try {
            let shaObj = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj.update(value);
            let saltText = shaObj.getHash("HEX");

            let shaObj2 = new jsSHA("SHA-256", "TEXT", { encoding: "UTF8" });
            shaObj2.update(saltText + securityKey);

            return shaObj2.getHash("HEX");
        } catch (err) {
            console.log("Error in " + this.displayName + ".calcHash function", err);
        }
    }

    uuidv4() {
        return '00-0-4-1-000'.replace(/[^-]/g,
            s => ((Math.random() + ~~s) * 0x10000 >> s).toString(16).padStart(4, '0')
        );
    }

    async getRedirectUser() {
        var request = {};
        request.Token = this.state.userToken;
        const requestParams = getRequestParams("POST", request);
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'RedirectUser', requestParams);
        const data = await response.json();
        if (data.payload != null) {
            this.setState({ userName: data.payload });
            this.login();
        }
    }

    componentDidMount() {
        if (this.state.userToken !== "") {
            this.getRedirectUser();
        }
        navigator.geolocation.getCurrentPosition(position => {
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
            this.setState({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
        })
        /*try {
            fetch("//api.ipify.org/?format=jsonp?callback=?",
                {
                    method: 'GET',
                    headers: {},
                })
                .then(response => { if (response.ok) { return response.text() } })
                .then(ip => {
                    console.log("ip", ip)
                    this.setState({
                        ipAddress: ip
                    });
                });
        } catch (err) {
            console.log("Error in " + this.displayName + ".componentDidMount function", err);
        }*/
    }

    async login() {
        try {            
            var request = {};
            var userName = this.state.userName;
            var password = this.state.password;
            const requestParams = getRequestParams('GET', request);
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'authentication/Validate/' + userName + "/" + password, requestParams);
            const data = await response.json();
            debugger;
            if (data.isSuccess) {
                sessionStorage.setItem("session_userId", data.payload.id);
                sessionStorage.setItem("session_userTypeId", data.payload.userTypeId);                
                sessionStorage.setItem("session_userTypeName", data.payload.userTypeName);
                sessionStorage.setItem("session_userName", data.payload.userName);
                sessionStorage.setItem("session_firstName", data.payload.firstName);
                sessionStorage.setItem("session_lastName", data.payload.lastName);
                sessionStorage.setItem("session_loginUserName", data.payload.loginUserName);
                sessionStorage.setItem("session_email", data.payload.email);
                sessionStorage.setItem("session_phoneNumber", data.payload.phoneNumber);
                sessionStorage.setItem("session_isActive", data.payload.isActive);
                sessionStorage.setItem("session_airlineCode", data.payload.airlineCode);
                sessionStorage.setItem("session_icaoCode", data.payload.icaoCode);
                sessionStorage.setItem("session_socketApiUrl", data.payload.socketApiUrl);                
                window.location.href = "/dashboard";
            }
            else {
                this.setState({ isSubmited: false, message: data.message });
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".componentDidMount function", err);
        }
    }

    async handleSubmit(e) {
        try {
            e.preventDefault();
            if (this.handleValidation()) {
                this.setState({ isSubmited: true });
                this.login();
            }
        } catch (err) {
            console.log("Error in " + this.displayName + ".handleSubmit function", err);
            this.setState({ isSubmited: false, message: "Oops something went worng !!!" });
        }
    }

    render() {
        return (
            <div>
                <section className="section">
                    <div className="d-flex flex-wrap align-items-stretch login">
                        <div className="col-lg-3 col-md-6 col-12 min-vh-100 bg-white">
                            <div>
                                <div className="text-center"><img src="https://track.utwiz.com/assets/img/logo_sst_utwiz2.png" alt="logo" width="300" className="mb-5 mt-3" /></div>
                                <form method="POST" action="#" className="needs-validation loginform" noValidate="">
                                    <h4 className="font-weight-normal text-center">Welcome to <img src="https://akasa.utwiz.com/assets/img/logo_akasa_air.png" alt="logo" width="150" className="" /></h4><p className="text-muted text-center">Before you get started, you must login.</p>
                                    <div className="form-group">
                                        <label htmlFor="email">Username</label>
                                        <input type="text" className="form-control" name="userName" maxLength={50} tabIndex="1" value={this.state.userName} onChange={this.handleInputChange} required autoFocus />
                                        <div className="error invalid-feedback">
                                            {this.state.errors["userName"].length > 0 && <span className='error-msg'>{this.state.errors["userName"]}</span>}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-block">
                                            <label htmlFor="password" className="control-label">Password</label>
                                        </div>
                                        <input id="password" type="password" className="form-control" maxLength={50} name="password" value={this.state.password} onChange={this.handleInputChange} tabIndex="2" required />
                                        <div className="error invalid-feedback">
                                            {this.state.errors["password"].length > 0 && <span className='error-msg'>{this.state.errors["password"]}</span>}
                                            {this.state.message.length > 0 && <span className='error-msg'>{this.state.message}</span>}
                                        </div>
                                    </div>
                                    {/* <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me" />
                                            <label className="custom-control-label" for="remember-me">Remember Me</label>
                                        </div>
                                    </div>
                                    <div className="form-group text-right">
                                        <a href="/forgotpassword" className="float-left mt-3">
                                            Forgot Password?
                                        </a>
                                        <button type="submit" className="btn btn-primary btn-lg btn-icon icon-right" tabindex="4" onClick={this.handleSubmit}>
                                            {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i> Login</span>}
                                            {!this.state.isSubmited && <span title="Login"><i className="fas fa-sign-in-alt"></i> Login</span>}
                                        </button>
                                    </div> */}

                                    <div className="form-group text-right">
                                        <div className="form-group float-left">
                                            <div className="custom-control custom-checkbox ">
                                                <input type="checkbox" name="remember" className="custom-control-input" tabIndex="3" id="remember-me" />
                                                <label className="custom-control-label" htmlFor="remember-me">Remember Me</label>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary icon-right" tabIndex="4" onClick={this.handleSubmit}>
                                            {this.state.isSubmited && <span title="Login"><i className="fas fa-spinner fa-spin"></i>&nbsp;&nbsp;Validating...</span>}
                                            {!this.state.isSubmited && <span title="Login"><i className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Login</span>}
                                        </button>
                                    </div>

                                    <div className="mt-3">
                                        <a href="/forgotpassword" className="mt-3">
                                            Need a help, logging in please click here.
                                        </a>
                                    </div>
                                </form>
                                <div className="text-center mt-5 text-small login-footer">
                                    <div className="mb-2">
                                        Powered by <a href="http://www.plexitech.com/"><img src="../assets/img/logo-plexitech2.png" alt="logo" width="150" /></a>
                                    </div>
                                    <div className="copyright">&copy; Copyright 2022 Akasa Air. <a href="https://www.akasaair.com/privacypolicy">
                                        Privacy Policy</a></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-12 min-vh-100 background-walk-y position-relative overlay-gradient-bottom" data-background="../assets/img/banner_akasa_air.jpg" style={{backgroundSize:"cover"}}>
                            <div className="absolute-bottom-left index-2">
                                <div className="text-white p-5 pb-2 color-overlay">
                                    <div className="pb-3">
                                        <h1 className="mb-2 display-5 font-weight-bold">Complete Crew & Logistics Solution</h1><br />
                                        <h6 className="font-weight-normal">
                                            <i className="fas fa-headset"></i> Support - From 10:00 am to 6:00 pm. Call 022 42129000
                                        </h6>
                                        <h6 className="font-weight-normal">
                                            <i className="fas fa-globe"></i> Nationwide - Go ahead with the BEST! All India sales and service network.
                                        </h6>
                                        <h6 className="font-weight-normal mb-5 ">
                                            <i className="fas fa-tint"></i> Saving - Guaranteed fuel savings. Calculate how much you will save with us!
                                        </h6>
                                        {/* <h6 className="font-weight-normal ">
                                            Get Our App! - Our mobile application for Android and iOS phones<br />gives you flexibility and control on the go.
                                        </h6>
                                        <a href="https://play.google.com/store/apps/details?id=com.silverstar.tracking">
                                            <img src="../assets/img/androidStore.png" alt="Google Play" /></a>
                                        <a href="https://apps.apple.com/in/app/sstracking/id1507947554">
                                            <img src="../assets/img/iosStore.png" alt="App Store" /></a> */}
                                    </div>
                                    {/* <div className="row" style={{width: '80%'}}>
                                        <div className="col">
                                            <div className="card mb-0">
                                                <div className="card-body text-dark" style={{padding: '15px'}}>
                                                    <h5 className="card-title mb-0" style={{color: '#5c0fd9'}}>Your Crew</h5>
                                                    <ul className="mb-0 pl-4">
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card mb-0">
                                                <div className="card-body text-dark" style={{padding: '15px'}}>
                                                    <h5 className="card-title mb-0" style={{color: '#5c0fd9'}}>Transportation</h5>
                                                    <ul className="mb-0 pl-4">
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="card mb-0">
                                                <div className="card-body text-dark" style={{padding: '15px'}}>
                                                    <h5 className="card-title mb-0" style={{color: '#5c0fd9'}}>Hotel</h5>
                                                    <ul className="mb-0 pl-4">
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                        <li>Lorem ipsum dolor sit amet</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}